<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a
            @click="back"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate"
              >PO Grup - Tambah</span
            >
          </span>
          <a href="javascript:void(0);" class="btn px-0 d-block d-md-none">
            <i class="fa fa-home text-main"></i>
          </a>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col with-fixed-sidebar">
          <div class="row mt-4 mb-5">
            <div class="col-12">
              <Notif />
              <Form
                @submit="tambah"
                :validation-schema="schema"
                v-slot="{ errors }"
                method="post"
                class="mb-3 needs-validation"
              >
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="nama"
                        >Nama PO Grup: <span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.name }"
                        id="name"
                        placeholder="Masukkan nama..."
                        name="name"
                        v-model="state.form.name"
                      />
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.name }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="sel1"
                        >Status:<span class="text-danger ml-1">*</span></label
                      >
                      <Field
                        name="status"
                        as="select"
                        class="form-control"
                        :class="{ 'is-invalid': errors.status }"
                        v-model="state.form.status"
                      >
                        <option :value="1">Aktif</option>
                        <option :value="0">Inaktif</option>
                      </Field>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.status }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="sel2"
                        >Produk Grup:<span class="text-danger ml-1"
                          >*</span
                        ></label
                      >
                      <Field
                        name="group_id"
                        as="select"
                        class="form-control"
                        :class="{ 'is-invalid': errors.group_id }"
                        v-model="state.form.group_id"
                      >
                        <option value="">Pilih produk grup...</option>
                        <option
                          v-for="gr in state.groups"
                          :key="gr.id"
                          :value="gr.id"
                          v-show="gr.name !== '#Non-PO'"
                        >
                          {{ gr.name }}
                        </option>
                      </Field>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.group_id }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="max">Maksimal Pesanan:</label>
                      <Field
                        type="number"
                        class="form-control"
                        :class="{ 'is-invalid': errors.max }"
                        id="max"
                        name="max"
                        v-model="state.form.max"
                      />
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.max }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="dline"
                        >Batas Akhir Pesanan:
                        <span class="text-danger">*</span></label
                      >
                      <Field
                        type="date"
                        class="form-control"
                        :class="{ 'is-invalid': errors.batas_akhir }"
                        id="batas_akhir"
                        name="batas_akhir"
                        v-model="state.form.batas_akhir"
                      />
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">
                        {{ errors.batas_akhir }}
                      </div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="delivery"
                        >Tanggal Pengiriman:
                        <span class="text-danger">*</span></label
                      >
                      <Field
                        type="date"
                        class="form-control"
                        :class="{ 'is-invalid': errors.tanggal_pengiriman }"
                        id="tanggal_pengiriman"
                        name="tanggal_pengiriman"
                        v-model="state.form.tanggal_pengiriman"
                      />
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">
                        {{ errors.tanggal_pengiriman }}
                      </div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="sel1"
                        >Lewat Reseller:<span class="text-danger ml-1"
                          >*</span
                        ></label
                      >
                      <Field
                        name="reseller_id"
                        as="select"
                        class="form-control"
                        :class="{ 'is-invalid': errors.reseller_id }"
                        v-model="state.form.reseller_id"
                      >
                        <option value="" selected>Tidak</option>
                        <option
                          v-for="res in state.reseller"
                          :key="res.id"
                          :value="res.id"
                        >
                          {{ res.name }} ({{ res.alamat }})
                        </option>
                      </Field>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">
                        {{ errors.reseller_id }}
                      </div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="catatan">Catatan:</label>
                      <Field
                        type="hidden"
                        name="catatan"
                        v-model="state.form.catatan"
                      />
                      <textarea
                        class="form-control"
                        :class="{ 'is-invalid': errors.catatan }"
                        rows="2"
                        id="catatan"
                        name="catatan"
                        v-model="state.form.catatan"
                      ></textarea>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.catatan }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                </div>
                <!-- .row -->

                <hr class="no-side-margin" />

                <div class="row mt-3">
                  <div class="col text-center">
                    <button type="submit" class="btn btn-main" title="Tambah" :disabled="state.loading">
                      <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="state.loading"></span>
                      <i class="fa fa-plus mr-1" v-else></i>Tambah
                    </button>
                    <button
                      type="reset"
                      class="btn btn-light ml-1"
                      title="Reset isian"
                    >
                      <i class="fas fa-sync-alt mr-1"></i>Reset
                    </button>
                    <a
                      href="#"
                      class="btn btn-light ml-1"
                      @click="batal"
                      title="Batal"
                      ><i class="fas fa-times mr-1"></i>Batal</a
                    >
                  </div>
                  <!-- .col-* -->
                </div>
                <!-- .row -->
              </Form>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import Notif from "../../../helpers/Notif.vue";
import { useVueSweetAlert2 } from "../../../useVueSweetAlert2.js";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const $swal = useVueSweetAlert2();
    const state = reactive({
      form: {
        name: "",
        status: 1,
        group_id: "",
        max: "",
        batas_akhir: "",
        tanggal_pengiriman: "",
        reseller_id: "",
        catatan: "",
      },
      loading: false,
      groups: {},
      reseller: {},
    });
    const batal = () => {
      $swal
        .fire({
          text: "Anda yakin meninggalkan form isian ini?  Perubahan tidak akan disimpan.",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            router.push({ path: "/jual/pesanan-group" });
          }
        });
    };
    const loadGroup = async () => {
      await axios.get("/api/seller/getGroup").then((res) => {
        if (res.data.success) {
          state.groups = res.data.data;
        }
      });
    };
    const loadReseller = async () => {
      await axios.get("/api/seller/getReseller").then((res) => {
        if (res.data.success) {
          state.reseller = res.data.data;
        }
      });
    };
    onMounted(() => {
      store.dispatch("error/setDefault");
      loadGroup();
      loadReseller();
    });
    //start validate
    const schema = Yup.object().shape({
      name: Yup.string().required("Nama tidak boleh kosong"),
      status: Yup.string().required("Pilih Salah satu"),
      group_id: Yup.string().required("Pilih Salah satu"),
      // max: Yup.string().required("Maximal Pesanan tidak boleh kosong"),
      batas_akhir: Yup.string().required("Batas akhir tidak boleh kosong"),
      tanggal_pengiriman: Yup.string().required(
        "Tanggal pengiriman tidak boleh kosong"
      ),
    });
    const tambah = () => {
      state.loading = true;
      axios.post("/api/seller/po-group", state.form).then((res) => {
        if (res.data.success) {
          $swal.fire({
            icon: "success",
            title: "Selamat",
            text: "PO Berhasil Ditambahkan!",
          });
          router.push({ path: "/jual/pesanan-group" });
        }
        state.loading = false;

      }, () => {
        state.loading = false;
      });
    };
    const back = () => {
      $swal.fire({
          text: "Anda yakin meninggalkan halaman ini saat proses belum selesai & data belum tersimpan?",
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
      }).then((result) => {
          if (result.isConfirmed) {
            window.history.back();
          }
      })
    }
    return {
      state,
      schema,
      tambah,
      batal,
      back
    };
  },
  components: {
    Field,
    Form,
    Notif,
  },
};
</script>